import React, { useState, useRef } from "react";
import { Label } from "./ui/label";
import { Input } from "./ui/input";

const generateUniqueId = () => {
  return `colorPicker_${Math.random().toString(36).substr(2, 9)}`;
};

type ColorPickerProps = {
  label?: string;
  fontColor: string;
  onFontColorChange: (color: string) => void;
};

const ColorPicker = ({
  label,
  fontColor,
  onFontColorChange,
}: ColorPickerProps) => {
  const [color, setColor] = useState(fontColor);
  const colorInputRef = useRef<HTMLInputElement>(null);
  const inputId = useRef<string>(generateUniqueId()).current;

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value;
    setColor(newColor);
    onFontColorChange(newColor);
  };

  const handleColorDivClick = () => {
    const colorInput = colorInputRef.current;
    if (colorInput) {
      colorInput.click();
    }
  };

  return (
    <div className="grid gap-2">
      {label && (
        <div className="flex items-center justify-between">
          <Label htmlFor={inputId}>{label}</Label>
        </div>
      )}
      <div className="flex items-center">
        <input
          type="color"
          id={inputId}
          value={color}
          onChange={handleColorChange}
          ref={colorInputRef}
          className="w-0"
        />
        <label
          htmlFor={inputId}
          className="h-9 w-12 rounded-md rounded-tr-none rounded-br-none border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 cursor-pointer"
          style={{ backgroundColor: color }}
          onClick={handleColorDivClick}
        ></label>

        <Input
          type="text"
          placeholder={label ?? ""}
          value={color}
          onChange={handleColorChange}
          className="border-l-0 rounded-tl-none rounded-bl-none"
        />
      </div>
    </div>
  );
};

export default ColorPicker;
