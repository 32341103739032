export function toBlobAsync(
  canvas: HTMLCanvasElement,
  mimeType: string,
  qualityArgument?: string
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Blob creation failed"));
        }
      },
      mimeType,
      qualityArgument
    );
  });
}

export const createCanvas = (canvas: HTMLCanvasElement, size: number) => {
  const newCanvas = document.createElement("canvas");
  newCanvas.width = size;
  newCanvas.height = size;

  const context = newCanvas.getContext("2d");
  if (context) {
    context.imageSmoothingQuality = "high";
    context.drawImage(canvas, 0, 0, size, size);
  }
  return newCanvas;
};

export const clearCanvas = (
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D
) => {
  // Reset transformations
  context.setTransform(1, 0, 0, 1, 0, 0);
  // Clear the canvas first
  context.clearRect(0, 0, canvas.width, canvas.height);
};

export const drawCanvasImage = (
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D,
  img: HTMLImageElement
) => {
  // Calculate the best size and position to maintain the aspect ratio of the image
  const aspectRatio = img.width / img.height;

  let drawWidth, drawHeight;

  if (canvas.width / aspectRatio < canvas.height) {
    drawWidth = canvas.width;
    drawHeight = canvas.width / aspectRatio;
  } else {
    drawWidth = canvas.height * aspectRatio;
    drawHeight = canvas.height;
  }

  const drawX = (canvas.width - drawWidth) / 2;
  const drawY = (canvas.height - drawHeight) / 2;

  context.drawImage(img, drawX, drawY, drawWidth, drawHeight);
};

export const scaleCanvasForHighRes = (
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D
) => {
  /**
   * Set the width and height of the canvas as 2x of the desired width and
   * height. Use the style attribute of the canvas to set the desired width
   * and height of the canvas then scale the content up by a factor of 2. This
   * will allow support for retina displays.
   */
  canvas.width = 2 * canvas.width;
  canvas.height = 2 * canvas.height;
  canvas.style.width = canvas.width / 2 + "px";
  canvas.style.height = canvas.height / 2 + "px";
  context.scale(2, 2);
};

export const drawBackground = (
  ctx: CanvasRenderingContext2D,
  shape: string,
  width: number,
  height: number,
  backgroundColor: string
) => {
  switch (shape) {
    case "square":
      drawSquare(ctx, width, height, backgroundColor);
      break;
    case "circle":
      drawCircle(ctx, width, height, backgroundColor);
      break;
    case "rounded":
      drawRounded(ctx, width, height, backgroundColor);
      break;
    default:
      drawSquare(ctx, width, height, backgroundColor);
      break;
  }
};

export const drawSquare = (
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number,
  color: string
) => {
  ctx.beginPath();
  ctx.rect(0, 0, width, height);
  ctx.fillStyle = color;
  ctx.fill();
};

export const drawCircle = (
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number,
  color: string
) => {
  ctx.beginPath();
  ctx.arc(width / 2, height / 2, height / 2, 0, 2 * Math.PI, false);
  ctx.fillStyle = color;
  ctx.fill();
};

export const drawRounded = (
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number,
  color: string
) => {
  ctx.beginPath();
  const radius = height / 10;
  ctx.moveTo(width, height);
  ctx.arcTo(0, height, 0, 0, radius);
  ctx.arcTo(0, 0, width, 0, radius);
  ctx.arcTo(width, 0, width, height, radius);
  ctx.arcTo(width, height, 0, height, radius);
  ctx.fillStyle = color;
  ctx.fill();
};

export const drawRoundedRectangle = (
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number,
  color: string,
  radius: number
) => {
  ctx.beginPath();

  // Calculate the maximum radius that can be used for the given width and height
  const maxRadius = Math.min(width, height) / 2;

  // Adjust the radius if it exceeds the maximum allowed value
  const adjustedRadius = Math.min(radius, maxRadius);

  ctx.moveTo(adjustedRadius, 0);
  ctx.lineTo(width - adjustedRadius, 0);
  ctx.arcTo(width, 0, width, adjustedRadius, adjustedRadius);
  ctx.lineTo(width, height - adjustedRadius);
  ctx.arcTo(width, height, width - adjustedRadius, height, adjustedRadius);
  ctx.lineTo(adjustedRadius, height);
  ctx.arcTo(0, height, 0, height - adjustedRadius, adjustedRadius);
  ctx.lineTo(0, adjustedRadius);
  ctx.arcTo(0, 0, adjustedRadius, 0, adjustedRadius);

  ctx.closePath();
  ctx.fillStyle = color;
  ctx.fill();
};

interface TextOptions {
  text: string;
  fontColor: string;
  fontFamily: string;
  fontSize: number;
}

export const drawText = (
  ctx: CanvasRenderingContext2D,
  canvasWidth: number,
  canvasHeight: number,
  options: TextOptions
) => {
  const { text, fontColor, fontFamily, fontSize } = options;

  // If text is empty, don't attempt to draw or measure offsets
  if (!text) {
    return;
  }

  ctx.fillStyle = fontColor;
  ctx.font = `${fontSize}px ${fontFamily}`;
  ctx.textBaseline = "alphabetic";
  ctx.textAlign = "center";
  const offsets = measureOffsets(ctx, text, fontFamily, fontSize);

  const x = canvasWidth / 2 + offsets.horizontal;
  const y = canvasHeight / 2 + offsets.vertical;

  ctx.fillText(text, x, y);
};

const measureOffsets = (
  ctx: CanvasRenderingContext2D,
  text: string,
  fontFamily: string,
  fontSize: number
) => {
  /**
   * Create and setup canvas
   */
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) return { vertical: 0, horizontal: 0 };
  context.font = `${fontSize}px ${fontFamily}`;

  /**
   * Make sure that there is enough room on the canvas for the text.
   * Changing the width or height of a canvas element clears the content,
   * so you need to set the font again.
   */
  canvas.width = 2 * ctx.measureText(text).width;
  canvas.height = 2 * fontSize;

  /**
   * Center the text vertically and horizontally using the built-in canvas
   * functionality (textBaseline and textAlign). We're going to measure how
   * far off the text is from the actual center since the textBaseline and
   * textAlign are not always accurate.
   */
  context.font = `${fontSize}px ${fontFamily}`;
  context.textBaseline = "alphabetic";
  context.textAlign = "center";
  context.fillStyle = "white";
  context.fillText(text, canvas.width / 2, canvas.height / 2);

  /**
   * Get image data so that we can iterate over every RGBA pixel.
   */
  const data = context.getImageData(0, 0, canvas.width, canvas.height).data;

  let textTop = 0;
  let textBottom = 0;
  for (let y = 0; y <= canvas.height; y++) {
    for (let x = 0; x <= canvas.width; x++) {
      let r_index = 4 * (canvas.width * y + x);
      let r_value = data[r_index];

      if (r_value === 255) {
        if (!textTop) {
          textTop = y;
        }
        textBottom = y;
        break;
      }
    }
  }

  /**
   * Vertical offset is the difference between the horizontal center of the
   * canvas and the horizontal center of the text on the canvas.
   */
  const canvasHorizontalCenterLine = canvas.height / 2;
  const textHorizontalCenterLine = (textBottom - textTop) / 2 + textTop;

  let textLeft = 0;
  let textRight = 0;
  for (let x = 0; x <= canvas.width; x++) {
    for (let y = 0; y <= canvas.height; y++) {
      let r_index = 4 * (canvas.width * y + x);
      let r_value = data[r_index];

      if (r_value === 255) {
        if (!textLeft) {
          textLeft = x;
        }
        textRight = x;
        break;
      }
    }
  }

  /**
   * Horizontal offset is the difference between the vertical center of the
   * canvas and the vertical center of the text on the canvas.
   */
  const canvasVerticalCenterLine = canvas.width / 2;
  const textVerticalCenterLine = (textRight - textLeft) / 2 + textLeft;

  return {
    vertical: canvasHorizontalCenterLine - textHorizontalCenterLine,
    horizontal: canvasVerticalCenterLine - textVerticalCenterLine,
  };
};
