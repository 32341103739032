import React, { useRef, useEffect, useState } from "react";
import {
  clearCanvas,
  drawCanvasImage,
  drawRoundedRectangle,
  drawText,
} from "../lib/canvasHelpers";

interface CanvasPreviewProps {
  bgColor: string;
  borderRadius: number;
  currentTab: string;
  fontColor: string;
  fontFamily: string;
  fontSize: number;
  imageUrl: string;
  onChange: (canvas: any) => void;
  text: string;
}

const CanvasPreview = ({
  bgColor,
  borderRadius,
  currentTab,
  fontColor,
  fontFamily,
  fontSize,
  imageUrl,
  onChange,
  text,
}: CanvasPreviewProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canvasSize, setCanvasSize] = useState({ width: 384, height: 384 });

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext("2d");
    if (!context) return;

    if (!canvas.style.width && !canvas.style.height) {
      // Only scale the canvas once when it's first created
      setCanvasSize((prevSize) => ({
        width: prevSize.width * 2,
        height: prevSize.height * 2,
      }));
    }

    if (imageUrl && currentTab === "image") {
      const img = new Image();
      img.crossOrigin = "anonymous";

      img.onload = function () {
        clearCanvas(canvas, context);
        drawCanvasImage(canvas, context, img);

        onChange(canvas);
      };

      img.src = imageUrl;
    } else if (currentTab === "text") {
      // If imageUrl is not provided, render a default avatar using CenterJS

      context.clearRect(0, 0, canvas.width, canvas.height);
      drawRoundedRectangle(
        context,
        canvasSize.width,
        canvasSize.height,
        bgColor,
        borderRadius
      );
      drawText(context, canvasSize.width, canvasSize.height, {
        text: text,
        fontColor: fontColor,
        fontFamily: fontFamily,
        fontSize: fontSize,
      });
      onChange(canvas);
    }
  }, [
    bgColor,
    borderRadius,
    canvasSize,
    currentTab,
    fontColor,
    fontFamily,
    fontSize,
    imageUrl,
    text,
  ]);

  return (
    <div>
      <canvas
        ref={canvasRef}
        width={canvasSize.width}
        height={canvasSize.height}
        style={{
          width: `${canvasSize.width / 2}px`,
          height: `${canvasSize.height / 2}px`,
        }}
        role="img"
        aria-label="Canvas Preview"
      >
        Sorry, your browser doesn't support canvas.
      </canvas>
    </div>
  );
};

export default CanvasPreview;
