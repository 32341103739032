import React from "react";
import { Outlet } from "react-router-dom";
import Toolbar from "../components/Toolbar";
import Appbar from "../components/Appbar";
import { Toaster } from "../components/ui/toaster";

const Layout = () => {
  return (
    <main className="flex flex-col min-h-screen bg-background" role="main">
      <header>
        <Appbar />
      </header>
      <div className="flex-grow overflow-y-auto" role="article">
        <Outlet />
      </div>
      <Toaster />
      <Toolbar />
    </main>
  );
};

export default Layout;
