import React from "react";
import GoogleAd from "../components/GoogleAd";
import Editor from "../components/Editor";

const MainPage = () => {
  return (
    <div className="p-8">
      <div className="w-full flex flex-col md:flex-row gap-4 md:gap-10">
        <div className="w-full flex flex-col-reverse lg:flex-row gap-4 md:gap-10">
          <aside className="w-full lg:w-[200px] min-w-[200px] max-h-[750px] overflow-auto flex-col space-y-4 pr-4 lg:flex lg:visible">
            <section>
              <h1 className="text-md text-foreground">Favicon Generator</h1>
              <p className="text-muted-foreground text-sm">
                Create a unique favicon for your brand or website with our
                intuitive favicon generator. Generate an .ICO favicon or a .PNG
                favicon from any image or text and download it in the latest
                formats compatible with all major browsers.
              </p>
            </section>
            <section>
              <h1 className="text-md text-foreground">What is a Favicon? </h1>
              <p className="text-muted-foreground text-sm">
                A favicon, often referred to as a website icon, is a small but
                crucial representation of your webpage or product across the
                web. It appears in browser tabs, history, search results,
                bookmarks, and address bars, serving as a visual identifier in
                .ICO or .PNG formats for your brand or website.
              </p>
            </section>
            <section>
              <h1 className="text-md text-foreground">
                Why Favicons Boost Brand Identity?
              </h1>
              <ul className="list-disc pl-5 text-muted-foreground text-sm">
                <li>
                  <span className="text-foreground">SEO Advantage:</span> A
                  properly implemented favicon can significantly improve your
                  website's search engine rankings, thereby enhancing your
                  site's visibility and clickthrough rate (CTR).
                </li>
                <li>
                  <span className="text-foreground">Brand Amplification:</span>{" "}
                  Favicons act as a constant visual cue, strengthening your
                  brand identity and raising brand awareness amongst your
                  audience.
                </li>
                <li>
                  <span className="text-foreground">
                    User Experience Enhancement:
                  </span>{" "}
                  Favicons, regardless of their size, play a significant role in
                  improving navigation and user experience on your website.
                </li>
                <li>
                  <span className="text-foreground">Trustworthiness:</span> An
                  authentic and consistent favicon makes your website appear
                  more credible and trustworthy to your visitors.
                </li>
              </ul>
            </section>
          </aside>
          <main className="w-full md:max-w-[65vw] lg:max-w-full flex gap-4 flex-col-reverse sm:flex-col">
            <GoogleAd adSlot="1217529817" className="h-[90px] w-full" />
            <Editor />
          </main>
        </div>

        <GoogleAd adSlot="8793547750" className="min-w-[200px] min-h-[400px]" />
      </div>
      <section className="mt-20 space-y-4">
        <h1 className="text-2xl text-foreground">Quick Tips</h1>
        <article>
          <h2 className="text-lg text-foreground">
            Choosing the Perfect Image for Your Favicon
          </h2>
          <p className="text-muted-foreground text-md">
            Your favicon represents your brand. Opt for simple designs and
            images that stand out even at smaller resolutions. A recognizable
            logo or brand symbol is often the best choice.
          </p>
        </article>
        <article>
          <h2 className="text-lg text-foreground">
            Maintaining Consistency Across Platforms
          </h2>
          <p className="text-muted-foreground text-md">
            Ensure your favicon looks consistent across all browsers and
            platforms. Testing its appearance can help in maintaining a
            professional brand image.
          </p>
        </article>
      </section>
      <section className="mt-4 space-y-4">
        <h1 className="text-lg text-foreground">Best Practices</h1>
        <ul className="list-disc pl-5 text-muted-foreground text-md">
          <li>Ensure your favicon is legible, even at small sizes.</li>
          <li>Use colors that match your brand palette.</li>
          <li>Regularly update your favicon to stay modern and fresh.</li>
        </ul>
      </section>
      <section className="mt-10 space-y-7">
        <h1 className="text-2xl text-foreground">Tutorial</h1>

        <article>
          <svg
            width="150px"
            height="150px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0802 7.89712C11.1568 5.96571 11.6952 5 12.5 5C13.3048 5 13.8432 5.96571 14.9198 7.89712L15.1984 8.3968C15.5043 8.94564 15.6573 9.22007 15.8958 9.40114C16.1343 9.5822 16.4314 9.64942 17.0255 9.78384L17.5664 9.90622C19.6571 10.3793 20.7025 10.6158 20.9512 11.4156C21.1999 12.2153 20.4872 13.0487 19.0619 14.7154L18.6932 15.1466C18.2881 15.6203 18.0856 15.8571 17.9945 16.1501C17.9034 16.443 17.934 16.759 17.9953 17.3909L18.051 17.9662C18.2665 20.19 18.3742 21.3019 17.7231 21.7962C17.072 22.2905 16.0932 21.8398 14.1357 20.9385L13.6292 20.7053C13.073 20.4492 12.7948 20.3211 12.5 20.3211C12.2052 20.3211 11.927 20.4492 11.3708 20.7053L10.8643 20.9385C8.90677 21.8398 7.928 22.2905 7.27688 21.7962C6.62575 21.3019 6.7335 20.19 6.94899 17.9662L7.00474 17.3909C7.06597 16.759 7.09659 16.443 7.00548 16.1501C6.91438 15.8571 6.71186 15.6203 6.30683 15.1466L5.93808 14.7154C4.51276 13.0487 3.8001 12.2153 4.04881 11.4156C4.29751 10.6158 5.34288 10.3793 7.43361 9.90622L7.9745 9.78384C8.56862 9.64942 8.86568 9.5822 9.1042 9.40114C9.34272 9.22007 9.4957 8.94565 9.80165 8.3968L10.0802 7.89712Z"
              className="fill-blue-900"
            />
            <path
              opacity="0.5"
              d="M4.86752 2.50058C4.89751 2.3948 5.08528 2.39416 5.11598 2.49974C5.25618 2.98185 5.51616 3.69447 5.90928 4.08495C6.30241 4.47543 7.01676 4.73058 7.49981 4.86752C7.6056 4.89751 7.60623 5.08528 7.50065 5.11598C7.01854 5.25618 6.30592 5.51616 5.91545 5.90928C5.52497 6.30241 5.26981 7.01676 5.13287 7.49981C5.10288 7.6056 4.91511 7.60623 4.88441 7.50065C4.74421 7.01854 4.48424 6.30592 4.09111 5.91545C3.69798 5.52497 2.98363 5.26981 2.50058 5.13287C2.3948 5.10288 2.39416 4.91511 2.49974 4.88441C2.98185 4.74421 3.69447 4.48424 4.08495 4.09111C4.47543 3.69798 4.73058 2.98363 4.86752 2.50058Z"
              className="fill-blue-600"
            />
            <path
              opacity="0.5"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19 3.25C19.4142 3.25 19.75 3.58579 19.75 4V4.25H20C20.4142 4.25 20.75 4.58579 20.75 5C20.75 5.41421 20.4142 5.75 20 5.75H19.75V6C19.75 6.41421 19.4142 6.75 19 6.75C18.5858 6.75 18.25 6.41421 18.25 6V5.75H18C17.5858 5.75 17.25 5.41421 17.25 5C17.25 4.58579 17.5858 4.25 18 4.25H18.25V4C18.25 3.58579 18.5858 3.25 19 3.25Z"
              className="fill-blue-400"
            />
          </svg>
          <h2 className="text-lg text-foreground">
            1. Decoding the Perfect Design
          </h2>
          <p className="text-muted-foreground text-md">
            While your brand may have intricate designs, a favicon necessitates
            simplicity due to its size. Focus on elements that capture your
            brand's essence. This could be the initials of your brand name, a
            simplified version of your logo, or any symbol that stands for your
            brand's vision.
          </p>
        </article>

        <article>
          <svg
            width="150px"
            height="150px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.75 19C7.75 19.4142 7.41421 19.75 7 19.75H5C4.58579 19.75 4.25 19.4142 4.25 19C4.25 18.5858 4.58579 18.25 5 18.25H7C7.41421 18.25 7.75 18.5858 7.75 19Z"
              fill="#1C274C"
            />
            <path
              opacity="0.4"
              d="M10 18V6C10 4.59987 10 3.8998 9.72752 3.36502C9.48783 2.89462 9.10538 2.51217 8.63498 2.27248C8.1002 2 7.40013 2 6 2C4.59987 2 3.8998 2 3.36502 2.27248C2.89462 2.51217 2.51217 2.89462 2.27248 3.36502C2 3.8998 2 4.59987 2 6V18C2 19.4001 2 20.1002 2.27248 20.635C2.51217 21.1054 2.89462 21.4878 3.36502 21.7275C3.8998 22 4.59987 22 6 22C7.40013 22 8.1002 22 8.63498 21.7275C9.10538 21.4878 9.48783 21.1054 9.72752 20.635C10 20.1002 10 19.4001 10 18Z"
              className="fill-blue-600"
            />
            <g opacity="0.7">
              <path
                d="M10 8.24276V18C10 18.9186 10 19.5359 9.92304 20.0029L13.2219 16.7041L19.0599 10.6145C20.0332 9.6111 20.5199 9.10939 20.6964 8.53425C20.847 8.04375 20.843 7.5188 20.685 7.03065C20.4997 6.45826 19.9999 5.95847 19.0003 4.95892C18.0991 4.07259 17.6484 3.62942 17.1204 3.44458C16.6857 3.29244 16.2175 3.2633 15.7673 3.36039C15.2204 3.47834 14.7183 3.86221 13.7141 4.62996L13 5.24276L10 8.24276Z"
                className="fill-blue-800"
              />
              <path
                d="M8.00288 21.923C8.00192 21.9232 8.00096 21.9234 8 21.9235V21.9259L8.00288 21.923Z"
                fill="#1C274C"
              />
            </g>
            <path
              d="M15.8143 14H17.8994C19.2995 14 19.9996 14 20.5344 14.2725C21.0048 14.5122 21.3872 14.8946 21.6269 15.365C21.8994 15.8998 21.8994 16.5999 21.8994 18C21.8994 19.4001 21.8994 20.1002 21.6269 20.635C21.3872 21.1054 21.0048 21.4878 20.5344 21.7275C19.9996 22 19.2995 22 17.8994 22H6C6.91721 22 7.53399 22 8.00069 21.9234L8 21.9259L8.00288 21.923C8.24762 21.8827 8.45107 21.8212 8.63498 21.7275C9.10538 21.4878 9.48783 21.1054 9.72752 20.635C9.82122 20.4511 9.8827 20.2476 9.92304 20.0029L13.2219 16.7041L15.8143 14Z"
              className="fill-blue-900"
            />
          </svg>
          <h2 className="text-lg text-foreground">
            2. Color Scheme and Visibility
          </h2>
          <p className="text-muted-foreground text-md">
            The colors of your favicon are critical. They need to resonate with
            your brand while ensuring high visibility against both light and
            dark browser themes. Use contrasting colors to ensure that the icon
            remains clearly visible regardless of the background.
          </p>
        </article>

        <article>
          <svg
            width="150px"
            height="150px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              opacity="0.5"
              cx="12"
              cy="12"
              r="10"
              className="fill-blue-600"
            />
            <path
              d="M13.9261 14.3018C14.1711 14.1107 14.3933 13.8885 14.8377 13.4441L20.378 7.90374C20.512 7.7698 20.4507 7.53909 20.2717 7.477C19.6178 7.25011 18.767 6.82414 17.9713 6.02835C17.1755 5.23257 16.7495 4.38186 16.5226 3.72788C16.4605 3.54892 16.2298 3.48761 16.0959 3.62156L10.5555 9.16192C10.1111 9.60634 9.88888 9.82854 9.69778 10.0736C9.47235 10.3626 9.27908 10.6753 9.12139 11.0062C8.98771 11.2867 8.88834 11.5848 8.68959 12.181L8.43278 12.9515L8.02443 14.1765L7.64153 15.3252C7.54373 15.6186 7.6201 15.9421 7.8388 16.1608C8.0575 16.3795 8.38099 16.4559 8.67441 16.3581L9.82308 15.9752L11.0481 15.5668L11.8186 15.31L11.8186 15.31C12.4148 15.1113 12.7129 15.0119 12.9934 14.8782C13.3243 14.7205 13.637 14.5273 13.9261 14.3018Z"
              className="fill-blue-900"
            />
            <path
              d="M22.1127 6.16905C23.2952 4.98656 23.2952 3.06936 22.1127 1.88687C20.9302 0.704377 19.013 0.704377 17.8306 1.88687L17.6524 2.06499C17.4806 2.23687 17.4027 2.47695 17.4456 2.7162C17.4726 2.8667 17.5227 3.08674 17.6138 3.3493C17.796 3.87439 18.14 4.56368 18.788 5.21165C19.4359 5.85961 20.1252 6.20364 20.6503 6.38581C20.9129 6.4769 21.1329 6.52697 21.2834 6.55399C21.5227 6.59693 21.7627 6.51905 21.9346 6.34717L22.1127 6.16905Z"
              className="fill-blue-900"
            />
          </svg>
          <h2 className="text-lg text-foreground">
            3. Using Our Favicon Generator
          </h2>
          <p className="text-muted-foreground text-md">
            Our intuitive tool aids in converting your design idea into a
            favicon. Upload a high-resolution image, and the generator will help
            you trim, adjust, and convert it into the ideal favicon formats,
            ensuring cross-browser compatibility.
          </p>
        </article>

        <article>
          <svg
            width="150px"
            height="150px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M14.0516 2C15.7318 1.99999 17.0627 1.99997 18.1043 2.14245C19.1762 2.28908 20.0438 2.59803 20.728 3.29418C21.4122 3.99033 21.7159 4.87308 21.86 5.9637C22 7.02342 22 8.37752 22 10.087V10.0871V13.913V13.913C22 15.6225 22 16.9766 21.86 18.0363C21.7159 19.1269 21.4122 20.0097 20.728 20.7058C20.0438 21.402 19.1762 21.7109 18.1043 21.8575C17.0627 22 15.7318 22 14.0517 22H14.0516H13.9484H13.9483C12.2681 22 10.9373 22 9.89575 21.8575C8.82382 21.7109 7.95621 21.402 7.27199 20.7058C6.58778 20.0097 6.28413 19.1269 6.14001 18.0363C5.99998 16.9766 5.99999 15.6225 6 13.913V13.9129V10.087V10.087C5.99999 8.3775 5.99998 7.02343 6.14001 5.9637C6.28413 4.87308 6.58778 3.99033 7.27199 3.29418C7.95621 2.59803 8.82382 2.28908 9.89575 2.14245C10.9373 1.99997 12.2682 1.99999 13.9484 2H13.9484H14.0516H14.0516Z"
              className="fill-blue-900"
            />
            <path
              d="M12 18.25C11.5858 18.25 11.25 18.5858 11.25 19C11.25 19.4142 11.5858 19.75 12 19.75H17C17.4142 19.75 17.75 19.4142 17.75 19C17.75 18.5858 17.4142 18.25 17 18.25H12Z"
              className="fill-blue-900"
            />
            <path
              d="M2 14.5C2 13.0955 2 12.3933 2.33706 11.8889C2.48298 11.6705 2.67048 11.483 2.88886 11.3371C3.39331 11 4.09554 11 5.5 11C6.90446 11 7.60669 11 8.11114 11.3371C8.32952 11.483 8.51702 11.6705 8.66294 11.8889C9 12.3933 9 13.0955 9 14.5V18.5C9 19.9045 9 20.6067 8.66294 21.1111C8.51702 21.3295 8.32952 21.517 8.11114 21.6629C7.60669 22 6.90446 22 5.5 22C4.09554 22 3.39331 22 2.88886 21.6629C2.67048 21.517 2.48298 21.3295 2.33706 21.1111C2 20.6067 2 19.9045 2 18.5V14.5Z"
              className="fill-blue-900"
            />
          </svg>
          <h2 className="text-lg text-foreground">4. Optimizing for Devices</h2>
          <p className="text-muted-foreground text-md">
            Different devices and platforms may display favicons differently.
            Our tool ensures that the generated favicon is optimized for desktop
            browsers, mobile browsers, and even when saved to a mobile device's
            home screen.
          </p>
        </article>

        <article>
          <svg
            width="150px"
            height="150px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.18744 15.0485C1.60418 16.6388 1.60418 19.2171 3.18744 20.8074C4.7707 22.3977 7.33768 22.3977 8.92094 20.8074L12.6667 17.0451L10.8946 16.3095C10.1177 15.9751 9.57994 15.2469 9.4866 14.4032C9.36539 13.3075 8.50424 12.4425 7.41335 12.3208H5.90317L3.18744 15.0485Z"
              className="fill-blue-900"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.3625 2.23307C13.6756 1.92134 14.1822 1.92246 14.4939 2.23556L21.767 9.54081C22.0787 9.85392 22.0776 10.3605 21.7645 10.6722C21.4513 10.9839 20.9448 10.9828 20.6331 10.6697L13.36 3.36444C13.0483 3.05133 13.0494 2.5448 13.3625 2.23307Z"
              className="fill-blue-900"
            />
            <path
              opacity="0.5"
              d="M14.0901 4.09766L3.18744 15.0485C1.60419 16.6388 1.60419 19.2171 3.18744 20.8074C4.7707 22.3976 7.33768 22.3976 8.92094 20.8074L19.8236 9.85652L14.0901 4.09766Z"
              className="fill-blue-900"
            />
          </svg>
          <h2 className="text-lg text-foreground">5. Iterative Testing</h2>
          <p className="text-muted-foreground text-md">
            Once you've generated your favicon, test it! See how it looks on
            various devices, under different browser themes, and even when
            bookmarked. This step ensures your brand is consistently represented
            everywhere.
          </p>
        </article>

        <article>
          <svg
            width="150px"
            height="150px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.8011 2.56967C10.8792 2.95444 10.6305 3.32966 10.2458 3.40776C6.81159 4.10481 4.10481 6.81156 3.40771 10.2457C3.3296 10.6305 2.95437 10.8791 2.56961 10.801C2.18484 10.7229 1.93624 10.3477 2.01435 9.9629C2.82504 5.96912 5.96917 2.82503 9.96297 2.0144C10.3477 1.9363 10.723 2.1849 10.8011 2.56967Z"
              className="fill-blue-900"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.56976 13.199C2.95452 13.1209 3.32975 13.3695 3.40786 13.7543C4.10496 17.1885 6.81174 19.8952 10.2459 20.5923C10.6307 20.6704 10.8793 21.0456 10.8012 21.4304C10.7231 21.8151 10.3479 22.0638 9.96312 21.9857C5.96932 21.175 2.82519 18.0309 2.0145 14.0371C1.9364 13.6524 2.18499 13.2772 2.56976 13.199Z"
              className="fill-blue-900"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.1989 2.56962C13.277 2.18485 13.6523 1.93625 14.037 2.01435C18.0308 2.82498 21.175 5.96907 21.9857 9.96285C22.0638 10.3476 21.8152 10.7228 21.4304 10.801C21.0456 10.8791 20.6704 10.6305 20.5923 10.2457C19.8952 6.81151 17.1884 4.10476 13.7542 3.40771C13.3695 3.32961 13.1208 2.95439 13.1989 2.56962Z"
              className="fill-blue-900"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.4304 13.199C21.8152 13.2772 22.0638 13.6524 21.9857 14.0371C21.175 18.0309 18.0308 21.175 14.037 21.9857C13.6523 22.0637 13.277 21.8151 13.1989 21.4304C13.1208 21.0456 13.3695 20.6704 13.7542 20.5923C17.1884 19.8952 19.8952 17.1885 20.5923 13.7543C20.6704 13.3695 21.0456 13.1209 21.4304 13.199Z"
              className="fill-blue-900"
            />
            <path
              opacity="0.5"
              d="M11.9998 19.5826C16.1877 19.5826 19.5826 16.1877 19.5826 11.9998C19.5826 7.81193 16.1877 4.41699 11.9998 4.41699C7.81193 4.41699 4.41699 7.81193 4.41699 11.9998C4.41699 16.1877 7.81193 19.5826 11.9998 19.5826Z"
              className="fill-blue-900"
            />
            <path
              d="M8.94088 14.5385C9.17468 14.2231 9.6199 14.1569 9.93531 14.3907C10.5249 14.8278 11.2357 15.0803 12.0001 15.0803C12.7645 15.0803 13.4753 14.8278 14.0649 14.3907C14.3803 14.1569 14.8255 14.2231 15.0593 14.5385C15.2931 14.8539 15.2269 15.2992 14.9115 15.5329C14.0906 16.1415 13.0859 16.5021 12.0001 16.5021C10.9143 16.5021 9.90961 16.1415 9.08866 15.5329C8.77325 15.2992 8.70709 14.8539 8.94088 14.5385Z"
              className="fill-blue-900"
            />
            <path
              d="M14.4882 11.6444C14.9462 11.6444 15.3176 11.0874 15.3176 10.4003C15.3176 9.71323 14.9462 9.15625 14.4882 9.15625C14.0302 9.15625 13.6588 9.71323 13.6588 10.4003C13.6588 11.0874 14.0302 11.6444 14.4882 11.6444Z"
              className="fill-blue-900"
            />
            <path
              d="M9.51199 11.6444C9.97003 11.6444 10.3414 11.0874 10.3414 10.4003C10.3414 9.71323 9.97003 9.15625 9.51199 9.15625C9.05394 9.15625 8.68262 9.71323 8.68262 10.4003C8.68262 11.0874 9.05394 11.6444 9.51199 11.6444Z"
              className="fill-blue-900"
            />
          </svg>
          <h2 className="text-lg text-foreground">
            6. Favicon and Brand Identity
          </h2>
          <p className="text-muted-foreground text-md">
            A favicon, though small, can be a potent tool in reinforcing your
            brand identity. Every time users open their bookmarks or look at
            their tabs, they're subtly reminded of your brand, leading to
            enhanced brand recall.
          </p>
        </article>
      </section>
      <section className="mt-10 space-y-4">
        <h1 className="text-2xl text-foreground">FAQ</h1>
        <dl className="space-y-4">
          <div>
            <dt className="text-lg text-foreground">Why do favicons matter?</dt>
            <dd className="text-muted-foreground text-md">
              Favicons enhance brand visibility and user experience, making
              websites appear professional and trustworthy.
            </dd>
          </div>

          <div>
            <dt className="text-lg text-foreground">
              Can I use any image as a favicon?
            </dt>
            <dd className="text-muted-foreground text-md">
              Ideally, simple and clear designs work best. Complex images can
              lose clarity when shrunk to favicon size.
            </dd>
          </div>

          <div>
            <dt className="text-lg text-foreground">
              How do I install the favicon on my website?
            </dt>
            <dd className="text-muted-foreground text-md">
              After downloading your favicon using our tool, place the files in
              the root directory of your website. You then need to add the
              provided code snippets to the {`<head>`} section of your website's
              HTML. Our platform even offers an easy 'copy' functionality to
              simplify this for you!
            </dd>
          </div>

          <div>
            <dt className="text-lg text-foreground">
              Why do I need different favicon sizes?
            </dt>
            <dd className="text-muted-foreground text-md">
              Different devices and browsers have varied specifications for
              displaying favicons. By having multiple sizes, you ensure your
              favicon is displayed optimally across all platforms, from desktop
              to mobile and even tablet devices.
            </dd>
          </div>

          <div>
            <dt className="text-lg text-foreground">
              What's the importance of the "apple-touch-icon" relation?
            </dt>
            <dd className="text-muted-foreground text-md">
              The "apple-touch-icon" relation is used specifically for Apple
              devices, like iPhones and iPads. When users add your website to
              their home screen, this icon is used. It's typically larger than
              standard favicons to cater to Apple's high-resolution Retina
              displays.
            </dd>
          </div>

          <div>
            <dt className="text-lg text-foreground">
              Can I customize the installation code?
            </dt>
            <dd className="text-muted-foreground text-md">
              Absolutely! While the provided code snippets work for most use
              cases, you can customize them to suit specific requirements.
              However, ensure the paths and filenames match where you've
              uploaded the favicon files on your server.
            </dd>
          </div>

          <div>
            <dt className="text-lg text-foreground">
              I lost my downloaded favicons. Can I retrieve them?
            </dt>
            <dd className="text-muted-foreground text-md">
              Our tool generates the favicon on-the-fly based on your inputs. If
              you lose your downloaded files, simply upload your image again and
              regenerate the favicons.
            </dd>
          </div>
        </dl>
      </section>
    </div>
  );
};

export default MainPage;
