export const files = [
  { name: "android-chrome-512x512", size: 512, type: "png" },
  { name: "android-chrome-192x192", size: 192, type: "png" },
  { name: "apple-touch-icon", size: 180, type: "png" },
  { name: "favicon-32x32", size: 32, type: "png" },
  { name: "favicon-16x16", size: 16, type: "png" },
];

export const availableFonts = [
  "Archivo",
  "Archivo Narrow",
  "Arimo",
  "Asap",
  "Bebas Neue",
  "Cabin",
  "Cabin Condensed",
  "Cairo",
  "Catamaran",
  "Crimson Text",
  "Droid Sans",
  "Exo",
  "Fira Sans",
  "IBM Plex Sans",
  "Inconsolata",
  "Inter",
  "Josefin Sans",
  "Kanit",
  "Karla",
  "Lato",
  "Lobster",
  "Lora",
  "Merriweather",
  "Montserrat",
  "Montserrat Alternates",
  "Mukta",
  "Noto Sans",
  "Noto Serif",
  "Nunito",
  "Nunito Sans",
  "Open Sans",
  "Oswald",
  "Pacifico",
  "Playfair Display",
  "Poppins",
  "PT Sans",
  "Quattrocento Sans",
  "Quicksand",
  "Raleway",
  "Roboto",
  "Roboto Condensed",
  "Roboto Mono",
  "Roboto Slab",
  "Rubik",
  "Saira",
  "Source Sans Pro",
  "Titillium Web",
  "Ubuntu",
  "Varela Round",
  "Work Sans",
  "Questrial",
].sort();
