import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div
      className="flex h-full w-full flex-col items-center justify-center bg-white"
      onClick={() => navigate("/")}
    >
      <div onClick={() => navigate("/")}>
        <h1 className="text-5xl font-bold text-slate-600">404 Not Found</h1>
      </div>
    </div>
  );
};

export default NotFoundPage;
