import React from "react";
import { useRouteError } from "react-router-dom";

const ErrorDisplay = () => {
  const error: any = useRouteError();

  return (
    <div className="flex h-full w-full flex-col items-center justify-center bg-slate-100">
      <h1 className="text-2xl font-bold text-slate-600">Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};

export default ErrorDisplay;
