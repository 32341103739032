import React from "react";
import { Button } from "./ui/button";
import { NavLink } from "react-router-dom";

const Toolbar = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="flex items-center w-full px-4 py-2">
      <NavLink to={`/privacy`} aria-label="Privacy Policy">
        <Button variant="link" className="text-muted-foreground">
          Privacy policy
        </Button>
      </NavLink>
      <NavLink to={`/terms`} aria-label="Terms of Service">
        <Button variant="link" className="text-muted-foreground">
          Terms of service
        </Button>
      </NavLink>
      <small className="text-muted-foreground text-xs ml-4">
        © {year} Favicon.Studio. All rights reserved.
      </small>
    </footer>
  );
};

export default Toolbar;
