import React, { useEffect, useRef } from "react";

const GoogleAd = ({
  dev,
  adSlot,
  className,
}: {
  dev?: boolean;
  adSlot: string;
  className?: string;
}) => {
  const adRef = useRef(null);

  useEffect(() => {
    try {
      const { current: adElement } = adRef;
      const w = window as any;
      if (adElement && typeof w !== "undefined") {
        w.adsbygoogle = w.adsbygoogle || [];
        if (dev) {
          w.adsbygoogle.requestNonPersonalizedAds = 1;
        }
        w.adsbygoogle.push({});
      }
    } catch (err: any) {
      console.log(err);
    }
  }, []);

  return (
    <div
      key={adSlot}
      className={`${
        dev ? "bg-blue-50 border border-solid border-blue-300" : ""
      }`}
    >
      <ins
        className={`adsbygoogle ${className}`}
        style={{ display: "block" }}
        data-ad-client="ca-pub-2848284817940708"
        data-ad-slot={adSlot}
        // data-ad-format="auto"
        data-adtest={dev ? "on" : null}
        // data-full-width-responsive="true"
        ref={adRef}
      />
    </div>
  );
};

export default GoogleAd;
