import React from "react";

const TermsOfService = () => {
  return (
    <div className="py-8 ml-8 bg-white space-y-4 mr-20">
      <h1 className="text-2xl font-bold mb-8">Terms of Service</h1>

      <div>
        <h2 className="text-xl font-semibold">User Responsibility</h2>
        <p className="text-muted-foreground">
          All users of this service are responsible for the content they
          generate. Users must ensure that they have the appropriate permissions
          or licenses for any images or content used in the creation of
          favicons. This includes, but is not limited to, the responsibility to
          avoid copyright infringement. We do not claim any responsibility for
          the images or content you choose to use or convert into favicon
          format.
        </p>
      </div>

      <div>
        <h2 className="text-xl font-semibold">Content Ownership</h2>
        <p className="text-muted-foreground">
          You retain full ownership of all materials you submit to our service,
          including the original images, generated files, and HTML code. You are
          free to use the generated content as you see fit, including but not
          limited to the installation of the generated favicon on your website.
          We cannot use your original or generated content without your prior,
          explicit consent.
        </p>
      </div>

      <div>
        <h2 className="text-xl font-semibold">Fonts</h2>
        <p className="text-muted-foreground">
          Our service includes access to fonts from the open source project
          Google Fonts. This resources comes with its own license, which we
          encourage you to review before use.
        </p>
      </div>

      <div>
        <h2 className="text-xl font-semibold">Service Provision</h2>
        <p className="text-muted-foreground">
          This is a free service offered as-is, with no warranty. All use of
          this service is at your own risk. We are not responsible for any data
          errors or discrepancies that may arise in the favicon files generated
          by our software.
        </p>
      </div>

      <p className="text-muted-foreground">
        By using this site to create your favicons, you acknowledge and agree to
        these terms of service.
      </p>
    </div>
  );
};

export default TermsOfService;
