import React, { useRef, useState } from "react";
import { Upload, RotateCcw } from "lucide-react";
import { useToast } from "./ui/use-toast";

type ImageUploadProps = {
  onUpload: (previewUrl: string) => void;
};

const ImageUpload = ({ onUpload }: ImageUploadProps) => {
  const { toast } = useToast();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState("");
  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: any) => {
    try {
      if (event.target.files && event.target.files[0]) {
        setSelectedFile(event.target.files[0]);
        // preview the file
        const url = URL.createObjectURL(event.target.files[0]);
        setPreviewURL(url);
        onUpload(url);
      } else {
        // reset state if no file was selected
        setSelectedFile(null);
        setPreviewURL("");
        if (fileInput.current) {
          fileInput.current.value = "";
        }
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "There was a problem with your upload.",
      });
    }
  };

  const triggerFileInput = () => {
    if (fileInput.current !== null) {
      fileInput.current.click();
    }
  };

  return (
    <div
      onClick={triggerFileInput}
      aria-label="Upload Image Container"
      className={`
      w-full 
      flex 
      items-center 
      rounded-lg 
      border-2 
      border-dashed 
      border-muted-foreground 
      p-2 
      text-center 
      hover:border-foreground
      focus:outline-none 
      focus:ring-2 
      focus:ring-indigo-500 
      focus:ring-offset-2 
      cursor-pointer
      ${previewURL ? "flex-row" : "flex-row"}
      `}
    >
      {previewURL && (
        <div>
          <img
            src={previewURL}
            alt="Uploaded Image Preview"
            className="w-24 h-auto rounded"
          />
        </div>
      )}
      <input
        type="file"
        id="fileInput"
        ref={fileInput}
        aria-label="Image Upload Input"
        className="hidden"
        accept=".svg, .png, .webp, .jpeg, .jpg, .gif"
        onChange={handleFileChange}
      />
      {!previewURL ? (
        <div className="flex w-full flex-col items-center p-2">
          <Upload className="text-foreground cursor-pointer" />
          <span className="mt-2 block text-sm font-semibold text-foreground cursor-pointer">
            Click to upload your image here
          </span>
        </div>
      ) : (
        <div className="flex w-full flex-col items-center p-2">
          <RotateCcw className="text-foreground cursor-pointer" />
          <span className="mt-2 block text-sm font-semibold text-foreground cursor-pointer">
            Upload another image
          </span>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
