import React from "react";
import { Link } from "react-router-dom";

const Appbar = () => {
  return (
    <nav className="flex items-center w-full p-4" aria-label="Main Navigation">
      <div className="flex items-center gap-2 rounded-full px-4">
        <Link to={`/`} aria-label="Home">
          <svg
            width="32"
            height="21"
            viewBox="0 0 102 91"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="faviconLogoTitle"
            role="img"
          >
            <title id="faviconLogoTitle">Favicon Studio Logo</title>
            <path
              d="M38.8757 6.99998C44.2643 -2.33335 57.7358 -2.33333 63.1244 7L99.4974 70C104.886 79.3334 98.1503 91 87.3731 91H14.6269C3.84971 91 -2.88602 79.3333 2.50258 70L38.8757 6.99998Z"
              className="fill-primary"
            />
          </svg>
        </Link>

        <h1 className="text-2xl font-bold text-foreground">Favicon.studio</h1>
      </div>

      <div className="flex items-center gap-2">
        <p className="text-foreground text-sm hidden sm:block">
          Online Favicon Generator
        </p>
      </div>
    </nav>
  );
};

export default Appbar;
