import React, { useState } from "react";
import { Slider } from "./ui/slider";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import ImageUpload from "./ImageUpload";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import ColorPicker from "./ColorPicker";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { availableFonts } from "../lib/constants";

type ControlsProps = {
  bgColor: string;
  borderRadius: number;
  fontColor: string;
  fontFamily: string;
  fontSize: number;
  onBgColorChange: (color: string) => void;
  onBorderRadiusChange: (radius: number) => void;
  onFontColorChange: (color: string) => void;
  onFontFamilyChange: (font: string) => void;
  onFontSizeChange: (size: number) => void;
  onImageUpload: (previewUrl: string) => void;
  onTabChange: (tabName: string) => void;
  onTextChange: (text: string) => void;
};

const Controls = ({
  bgColor,
  borderRadius,
  fontColor,
  fontFamily,
  fontSize,
  onBgColorChange,
  onBorderRadiusChange,
  onFontColorChange,
  onFontFamilyChange,
  onFontSizeChange,
  onImageUpload,
  onTabChange,
  onTextChange,
}: ControlsProps) => {
  return (
    <section className="w-full" aria-label="Customization Controls">
      <Tabs defaultValue="text">
        <TabsList className="w-full">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="w-full">
                <TabsTrigger
                  value="text"
                  className="w-full"
                  onClick={() => onTabChange("text")}
                >
                  Use text
                </TabsTrigger>
              </TooltipTrigger>
              <TooltipContent className="p-2">
                <p className="text-md text-slate-600">
                  Generate the favicon from text
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="w-full">
                <TabsTrigger
                  value="image"
                  className="w-full"
                  onClick={() => onTabChange("image")}
                >
                  Use image
                </TabsTrigger>
              </TooltipTrigger>
              <TooltipContent className="p-2">
                <p className="text-md text-slate-600">
                  Generate the favicon from an image
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </TabsList>
        <TabsContent value="text">
          <article className="flex flex-col gap-4 mt-4">
            <div>
              <Label htmlFor="email">Text</Label>
              <Input
                type="text"
                placeholder="The text for your favicon..."
                className="mt-2"
                defaultValue="F"
                onChange={(e) => onTextChange(e.target.value)}
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Font family</Label>
              <Select
                onValueChange={onFontFamilyChange}
                defaultValue={fontFamily}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a font family" />
                </SelectTrigger>
                <SelectContent className="max-h-[250px]">
                  {availableFonts.map((font) => (
                    <SelectItem key={font} value={font}>
                      {font}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="grid gap-2">
              <div className="flex items-center justify-between">
                <Label htmlFor="fontSize">Font Size</Label>
                <span className="w-12 rounded-md border border-transparent px-2 py-0.5 text-right text-sm text-muted-foreground hover:border-border">
                  {fontSize}
                </span>
              </div>
              <Slider
                id="fontSize"
                max={350}
                min={40}
                defaultValue={[fontSize]}
                step={1}
                onValueChange={(v) => onFontSizeChange(v[0])}
                className="[&_[role=slider]]:h-4 [&_[role=slider]]:w-4"
                aria-label="Change font size of the favicon"
              />
            </div>

            <ColorPicker
              label={"Font color"}
              fontColor={fontColor}
              onFontColorChange={onFontColorChange}
            />

            <ColorPicker
              label={"Background color"}
              fontColor={bgColor}
              onFontColorChange={onBgColorChange}
            />

            <div className="grid gap-2">
              <div className="flex items-center justify-between">
                <Label htmlFor="roundness">Roundness</Label>
                <span className="w-12 rounded-md border border-transparent px-2 py-0.5 text-right text-sm text-muted-foreground hover:border-border">
                  {borderRadius}
                </span>
              </div>
              <Slider
                id="roundness"
                max={100}
                min={0}
                defaultValue={[borderRadius]}
                step={1}
                onValueChange={(v) => onBorderRadiusChange(v[0])}
                className="[&_[role=slider]]:h-4 [&_[role=slider]]:w-4"
                aria-label="Change roundness of the favicon"
              />
            </div>
          </article>
        </TabsContent>
        <TabsContent value="image" className="pt-3">
          <ImageUpload
            onUpload={onImageUpload}
            aria-label="Upload image for favicon"
          />
        </TabsContent>
      </Tabs>
    </section>
  );
};

export default Controls;
