import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="py-8 ml-8 bg-white space-y-4 mr-20">
      <h1 className="text-2xl font-bold mb-8">Privacy Policy</h1>

      <div>
        <h2 className="text-xl font-semibold">At A Glance</h2>
        <p className="text-muted-foreground">
          We do not amass any personal data. We collaborate with third-party
          services which utilize cookies. These third-party services are used to
          compile data about website usage. We employ third-party services for
          advertising purposes.
        </p>
      </div>

      <div>
        <h2 className="text-xl font-semibold">External Service Providers</h2>
        <p className="text-muted-foreground">
          Google Analytics, Google Adsense
        </p>
      </div>

      <div>
        <h2 className="text-xl font-semibold">Visitor Analytics</h2>
        <p className="text-muted-foreground">
          In alignment with standard practices for web operations, our platform,
          favicon.studio, obtains non-personal data provided by web browsers and
          servers. This includes data like your browser type, preferred
          language, referring website, and the time of your visits. The primary
          goal behind the collection of this non-personal data by favicon.studio
          is to gain a deeper understanding of visitor interactions on our
          website. We may occasionally release aggregated non-personal data to
          highlight website usage patterns.
        </p>
      </div>

      <div>
        <h2 className="text-xl font-semibold">Behavioural Analytics</h2>
        <p className="text-muted-foreground">
          favicon.studio may gather data regarding visitor interactions to glean
          insights into user engagement. For instance, we may keep track of the
          most frequently visited pages on favicon.studio. This information may
          be disclosed publicly or shared with third parties. However, we assure
          that personally-identifiable information will not be disclosed.
        </p>
      </div>

      <div>
        <h2 className="text-xl font-semibold">Cookie Usage</h2>
        <p className="text-muted-foreground">
          A cookie refers to a string of data that a website stores on a
          visitor's device and is retrieved by the website each time the visitor
          returns. favicon.studio uses cookies to recognize visitors, understand
          their usage of our website, and remember their website access
          preferences. If you prefer not to have cookies stored on your device,
          please adjust your browser settings to refuse cookies before visiting
          favicon.studio. Note that opting out of cookies may limit the
          functionality of certain features on our site.
        </p>
      </div>

      <div>
        <h2 className="text-xl font-semibold">Advertisements</h2>
        <p className="text-muted-foreground">
          Ads that appear on our website are delivered through advertising
          partners who may use cookies. These cookies allow the ad server to
          identify your device each time they send an online ad, and compile
          information about you or other users of your device. This helps ad
          networks to deliver targeted ads that they believe will be of most
          interest to you. Our Privacy Policy covers the use of cookies by
          favicon.studio but does not extend to cookie usage by any advertisers.
        </p>
      </div>

      <div>
        <h2 className="text-xl font-semibold">Amendments to Privacy Policy</h2>
        <p className="text-muted-foreground">
          favicon.studio reserves the right to make changes to this Privacy
          Policy from time to time, and solely at our discretion. We encourage
          visitors to regularly check this page for any updates to our Privacy
          Policy. Continued use of our website following any changes in this
          Privacy Policy constitutes your acceptance of such changes.
        </p>
      </div>

      <p className="text-muted-foreground">
        By using our website, you acknowledge and agree to this Privacy Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
