import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorDisplay from "./components/ErrorDisplay";
import NotFoundPage from "./pages/NotFoundPage";
import Layout from "./pages/Layout";
import MainPage from "./pages/MainPage";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <ErrorDisplay />,
      children: [
        { path: "/", element: <MainPage /> },
        { path: "/terms", element: <TermsOfService /> },
        { path: "/privacy", element: <PrivacyPolicy /> },
      ],
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ]);

  return (
    <div className="h-full w-full">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
