import React, { useState } from "react";
import Controls from "./Controls";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Button } from "./ui/button";
import { Download } from "lucide-react";
import CanvasPreview from "./CanvasPreview";
import JSZip from "jszip";
import { createCanvas, toBlobAsync } from "../lib/canvasHelpers";
import { useToast } from "./ui/use-toast";
import { PngIcoConverter } from "../png2icojs";
import { files } from "../lib/constants";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";

const Editor = () => {
  const { toast } = useToast();
  const [currentTab, setCurrentTab] = useState("text");
  const [text, setText] = useState<string>("F");
  const [fontSize, setFontSize] = useState<number>(240);
  const [fontColor, setFontColor] = useState("#FFFFFF");
  const [fontFamily, setFontFamily] = useState("Inter");
  const [bgColor, setBgColor] = useState<string>("#475569");
  const [borderRadius, setBorderRadius] = useState<number>(10);
  const [imageUrlPreview, setImageUrlPreview] = useState<string>("");
  const [canvas, setCanvas] = useState<HTMLCanvasElement>();
  const [installationDialogOpen, setInstallationDialogOpen] = useState(false);

  const generateIcons = async (canvas: HTMLCanvasElement, zip: JSZip) => {
    for (const file of files) {
      const canvasFile = createCanvas(canvas, file.size);
      const pngBlob = await toBlobAsync(canvasFile, "image/png");
      zip.file(`${file.name}.png`, pngBlob);
    }
  };

  const generateFavicon = async (canvas: HTMLCanvasElement, zip: JSZip) => {
    const faviconSizes = [64, 32, 24, 16];
    const converter = new PngIcoConverter();

    // create array for multiple sizes in the .ico
    let iconBlobs = [];
    for (const size of faviconSizes) {
      const canvasIcon = createCanvas(canvas, size);
      const iconBlob = await toBlobAsync(canvasIcon, "image/png");
      iconBlobs.push({ png: iconBlob });
    }

    const icoBlob = await converter.convertToBlobAsync(iconBlobs); // Default mime type is image/x-icon
    zip.file("favicon.ico", icoBlob);
  };

  const downloadZipFile = async (zip: JSZip) => {
    const zipBlob = await zip.generateAsync({ type: "blob" });
    const link = document.createElement("a");
    link.download = "favicon_studio_files.zip";
    link.href = URL.createObjectURL(zipBlob);
    link.click();
  };

  const handleDownload = async () => {
    if (canvas) {
      try {
        let zip = new JSZip();
        await generateIcons(canvas, zip);
        await generateFavicon(canvas, zip);
        await downloadZipFile(zip);
      } catch (error) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "There was a problem with your download.",
        });
      }
    }
  };

  return (
    <Card className="w-full rounded-lg">
      <CardHeader>
        <CardTitle>Create favicon</CardTitle>
        <CardDescription>
          Generate your favicon with your own custom style.
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col sm:flex-row gap-4">
        <Controls
          bgColor={bgColor}
          borderRadius={borderRadius}
          fontColor={fontColor}
          fontFamily={fontFamily}
          fontSize={fontSize}
          onBgColorChange={setBgColor}
          onBorderRadiusChange={setBorderRadius}
          onFontColorChange={setFontColor}
          onFontFamilyChange={setFontFamily}
          onFontSizeChange={setFontSize}
          onImageUpload={setImageUrlPreview}
          onTabChange={setCurrentTab}
          onTextChange={setText}
        />
        <div className="w-full h-[390px] flex items-center justify-center">
          <CanvasPreview
            text={text}
            fontSize={fontSize}
            fontColor={fontColor}
            fontFamily={fontFamily}
            bgColor={bgColor}
            borderRadius={borderRadius}
            imageUrl={imageUrlPreview}
            currentTab={currentTab}
            onChange={setCanvas}
          />
        </div>
      </CardContent>
      <CardFooter className="flex justify-end gap-4">
        <Dialog
          open={installationDialogOpen}
          onOpenChange={setInstallationDialogOpen}
          aria-label="Installation Instructions Dialog"
        >
          <DialogTrigger asChild>
            <Button
              onClick={handleDownload}
              disabled={!canvas}
              size="default"
              aria-label="Download Button"
            >
              <Download className="mr-3 h-5 w-5" />
              <span className="text-lg">Download</span>
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:min-w-[700px]">
            <DialogHeader>
              <DialogTitle>Installation</DialogTitle>
              <DialogDescription>
                Your files are now downloaded. Place the favicons in the root
                directory of your website. Then add the following code to the{" "}
                {`<`}head{`>`} section of your HTML file:
              </DialogDescription>
            </DialogHeader>
            <div>
              <pre className="p-6 mt-2 text-sm bg-gray-800 rounded-md overflow-auto whitespace-normal">
                <code className="text-xs text-green-400">
                  <span className="block">
                    <span className="text-blue-500">&lt;link </span>
                    <span className="text-red-500">
                      rel="apple-touch-icon"{" "}
                    </span>
                    <span className="text-red-500">sizes="180x180" </span>
                    <span className="text-red-500">
                      href="/apple-touch-icon.png"{" "}
                    </span>
                    <span className="text-blue-500">&gt;&lt;/link&gt;</span>
                  </span>

                  <span className="block">
                    <span className="text-blue-500">&lt;link </span>
                    <span className="text-red-500">rel="icon" </span>
                    <span className="text-red-500">type="image/png" </span>
                    <span className="text-red-500">sizes="32x32" </span>
                    <span className="text-red-500">
                      href="/favicon-32x32.png"{" "}
                    </span>
                    <span className="text-blue-500">&gt;&lt;/link&gt;</span>
                  </span>

                  <span className="block">
                    <span className="text-blue-500">&lt;link </span>
                    <span className="text-red-500">rel="icon" </span>
                    <span className="text-red-500">type="image/png" </span>
                    <span className="text-red-500">sizes="16x16" </span>
                    <span className="text-red-500">
                      href="/favicon-16x16.png"{" "}
                    </span>
                    <span className="text-blue-500">&gt;&lt;/link&gt;</span>
                  </span>
                </code>
              </pre>
            </div>
            <DialogFooter>
              <Button
                onClick={() =>
                  navigator.clipboard
                    .writeText(
                      `
                  <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" ></link>
                  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" ></link>
                  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" ></link>
                  `
                    )
                    .then(() => {
                      setInstallationDialogOpen(false);
                    })
                }
                aria-label="Copy Button"
              >
                Copy
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </CardFooter>
    </Card>
  );
};

export default Editor;
